<template>
  <v-card tile class="mx-auto" :disabled="table_loading">
    <!-- DATA TABLE start-->
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                !loading && table_data ? `(${table_data.length})` : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              rha &&
              rha.letter &&
              login.permissions.rha_letters.create &&
              (!rha.last_rha_letter_id || rha.is_express)
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                color="success"
                @click="formShow(0)"
              >
                <v-icon v-text="'mdi-plus'" />
              </v-btn>
            </template>
            <span v-text="'Agregar'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" v-if="!table_loading && !rha.insured_vality">
          <v-alert shaped prominent type="error">
            <v-row align="center">
              <v-col class="grow">
                <span class="text-caption">
                  <b>Vigencia vencida</b>
                  <br />
                  Da click en el botón azul para actualizar al asegurado.
                </span>
              </v-col>
              <v-col class="shrink">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-1"
                      v-on="on"
                      fab
                      x-small
                      color="info"
                      :to="{
                        name: 'asegurados.view',
                        params: {
                          contractor_id: rha.contractor_id,
                          id: rha.insured_id,
                        },
                      }"
                    >
                      <v-icon v-text="'mdi-clipboard-account'" />
                    </v-btn>
                  </template>
                  <span v-text="'Ver asegurado'" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col cols="12" xs="12" md="12">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip
                  v-if="
                    !rha.last_rha_letter_id && page_route != 'programaciones'
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-1"
                      v-on="on"
                      icon
                      color="warning"
                      :disabled="
                        (last_letter_doctors > 0 &&
                          (last_letter_id == null || rha.insured_id == null)) ||
                        (last_letter_doctors == 0 && last_letter_id == null)
                      "
                      @click.prevent="
                        detailShow(last_letter_id, false, false, true)
                      "
                    >
                      <v-icon> mdi-file-star </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Carta definitiva'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    rha.last_rha_letter_id &&
                    login.role_id == 1 &&
                    !rha.is_express
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-1"
                      v-on="on"
                      icon
                      color="error"
                      @click.prevent="removeLastLetter(rha)"
                    >
                      <v-icon> mdi-file-replace </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'ELIMINAR carta definitiva'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text v-if="table_data">
              <v-row>
                <v-col cols="12" xs="12" md="12">
                  <v-data-table
                    :headers="table_headers"
                    :items="table_data"
                    :search="table_search"
                    :loading="table_loading"
                    :item-class="itemRowBackground"
                  >
                    <template v-slot:item.admision="{ item }">
                      <div>
                        {{ letter_types[item.letter_type_id - 1].name }}
                      </div>
                    </template>
                    <template v-slot:item.reviews="{ item }">
                      <div v-if="item.is_express">N/A</div>
                      <div v-else>
                        <div
                          v-if="
                            item.review > rha.contractor_detail.letters_review
                          "
                        >
                          TERMINADA
                        </div>
                        <div class="orange--text text--darken-3" v-else>
                          REV. {{ item.review }}
                        </div>
                        <div v-if="item.id == rha.last_rha_letter_id">
                          DEFINITIVA
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.is_express="{ item }">
                      {{ item.is_express ? "ÉXPRES" : "DICTAMEN" }}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="secondary"
                            @click="detailShow(item.id)"
                          >
                            <v-icon v-text="'mdi-eye'" />
                          </v-btn>
                        </template>
                        <span v-text="'Detalle'" />
                      </v-tooltip>
                      <v-tooltip
                        left
                        v-if="
                          !item.is_express &&
                          item.review <= rha.contractor_detail.letters_review &&
                          login.permissions.rha_letters.update
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="info"
                            @click="formShow(item.id)"
                          >
                            <v-icon v-text="'mdi-pencil'" />
                          </v-btn>
                        </template>
                        <span v-text="'Editar'" />
                      </v-tooltip>
                      <v-tooltip
                        left
                        v-if="
                          !item.is_express &&
                          (item.review === login.letters_review ||
                            login.letters_review === 9) &&
                          item.review <= rha.contractor_detail.letters_review &&
                          (login.role_id == 1 ||
                            !item.rha_letter_review ||
                            item.rha_letter_review.created_by_id != login.id)
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            @click="reviewAlert(item.review, item.id)"
                          >
                            <v-icon v-text="'mdi-text-box-multiple'" />
                          </v-btn>
                        </template>
                        <span v-text="'Revisar'" />
                      </v-tooltip>
                      <!-- <v-tooltip
                        left
                        v-if="
                          item.review > rha.contractor_detail.letters_review
                        "
                      > -->
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="success"
                            :href="url_pdf + '/carta/' + item.id + '/' + 1"
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-printer'" />
                          </v-btn>
                        </template>
                        <span v-text="'Ver carta'" />
                      </v-tooltip>
                      <v-tooltip v-if="!item.is_express" left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="info"
                            @click.prevent="historyReviewsDialog(item)"
                          >
                            <v-icon v-text="'mdi-history'" />
                          </v-btn>
                        </template>
                        <span v-text="'Historial de revisiones'" />
                      </v-tooltip>
                      <v-tooltip
                        v-if="
                          !item.is_express &&
                          rha.insured_id &&
                          (login.role_id == 1 || login.role_id == 11)
                        "
                        left
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            :color="item.comment ? 'info' : 'warning'"
                            @click.prevent="rhaLetterCommentDlg(item)"
                          >
                            <v-icon> mdi-note-text </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Análisis dictamen'" />
                      </v-tooltip>
                      <v-tooltip
                        v-if="
                          rha.insured_id &&
                          (login.role_id == 1 || login.role_id == 11) &&
                          item.comment
                        "
                        left
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            dark
                            color="pink"
                            @click.prevent="assesmentRepDlg(item.id)"
                          >
                            <v-icon> mdi-file </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'VALORACIÓN DICTAMEN'" />
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DATA TABLE end-->
    <!-- DIALOG FORM start-->
    <v-dialog
      v-model="form_dialog"
      scrollable
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title
            v-text="
              `${$route.meta.title} | ${this.form_store ? 'Agregar' : 'Editar'}`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="formDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'INFORMACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="No. poliza"
                          :value="rha.insured.policy"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Titular"
                          :value="
                            rha.dependent_name +
                            ' ' +
                            rha.dependent_first_surname +
                            ' ' +
                            rha.dependent_second_surname +
                            (rha.insured_id
                              ? ' (' +
                                (rha.dependent_antiquity
                                  ? rha.dependent_antiquity
                                  : 'ANTIGUEDAD NO REG.') +
                                ')'
                              : '')
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Paciente"
                          :value="
                            rha.name +
                            ' ' +
                            rha.first_surname +
                            ' ' +
                            rha.second_surname +
                            (rha.insured_id
                              ? ' (' +
                                (rha.insured.antiquity
                                  ? rha.insured.antiquity
                                  : 'ANTIGUEDAD NO REG.') +
                                ')'
                              : '')
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Tipo asegurado"
                          :value="rha.insured.insured_type.insured_type"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          :class="`text-caption${
                            !rha.insured_id
                              ? ' orange--text text--darken-3'
                              : ''
                          }`"
                          label="F. nacimiento"
                          :value="rha.insured ? rha.insured.birthday : '-'"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          :class="`text-caption${
                            !rha.insured_id
                              ? ' orange--text text--darken-3'
                              : ''
                          }`"
                          label="Vigencia"
                          :value="rha.insured ? rha.insured.validity : '-'"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="ID SM"
                          :value="
                            rha.insured_id
                              ? rha.insured.enrollment
                              : rha.enrollment
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData label="Contratante" :value="rha.contractor" />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Hospital"
                          :value="rha.provider.trade_name"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2
                      class="text-caption"
                      v-text="'DIAGNOSTICO DEFINITIVO (ICD)'"
                    />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-form v-on:submit.prevent ref="icd_form" lazy-validation>
                      <v-row dense>
                        <v-col cols="12" class="mt-4">
                          <v-autocomplete
                            label="Descripción"
                            v-model="icd"
                            :items="icds"
                            item-value="id"
                            :item-text="(item) => `${item.code} | ${item.icd}`"
                            :loading="icds_loading"
                            return-object
                            dense
                            :rules="rules.required"
                          >
                            <template v-slot:item="{ item }">
                              <div class="text-caption d-flex flex-column">
                                {{ `${item.code} | ${item.icd}` }}
                              </div>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            dark
                            small
                            block
                            color="orange darken-2"
                            @click="icdAdd"
                          >
                            Agregar
                            <v-icon right> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="mb-5">
                          <hr />
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th v-text="'#'" />
                                  <th v-text="'Código'" />
                                  <th v-text="'Descripción'" />
                                  <th v-text="'Acción'" />
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, i) in data.icds"
                                  :key="i"
                                  v-if="item.active"
                                >
                                  <td v-text="`${i + 1}`" />
                                  <td v-text="item.code" />
                                  <td v-text="item.icd" />
                                  <td>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          text
                                          icon
                                          x-small
                                          color="error"
                                          @click="icdRemove(i)"
                                        >
                                          <v-icon medium v-text="'mdi-close'" />
                                        </v-btn>
                                      </template>
                                      <span
                                        v-text="`Eliminar ICD (${i + 1})`"
                                      />
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col v-if="sinister_rep" cols="12">
                          <v-row dense>
                            <v-col cols="12" />
                            <v-col cols="12">
                              <v-divider />
                            </v-col>
                            <v-col cols="12">
                              <h4 v-text="'SINIESTRALIDAD'" />
                            </v-col>
                          </v-row>
                          <v-row
                            dense
                            v-for="(icd, i) in sinister_rep.list"
                            :key="i"
                          >
                            <v-col cols="12">
                              <span
                                class="text-caption"
                                v-text="icd.code + ' | ' + icd.icd"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th v-text="'#'" />
                                      <th v-text="'Tipo'" />
                                      <th v-text="'Proveedor'" />
                                      <th v-text="'T. Prov. | Espec.'" />
                                      <th v-text="'Folio'" />
                                      <th v-text="'Fecha'" />
                                      <th v-text="'Padecimiento'" />
                                      <th v-text="'Siniestro'" />
                                      <th v-text="'Núm. Siniestro'" />
                                      <th v-text="'Deducible'" />
                                      <th v-text="'Coaseguro %'" />
                                      <th v-text="'Facturado'" />
                                      <th v-text="'Monto'" />
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, i) in icd.items" :key="i">
                                      <td v-text="`${i + 1}`" />
                                      <td v-text="item.type" />
                                      <td v-text="item.provider" />
                                      <td v-text="item.provider_type" />
                                      <td v-text="item.folio" />
                                      <td v-text="item.date" />
                                      <td
                                        v-text="
                                          item.accident
                                            ? 'ACCIDENTE'
                                            : 'ENFERMEDAD'
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.initial
                                            ? 'INICIAL'
                                            : 'COMPLEMENTARIO'
                                        "
                                      />
                                      <td v-text="item.sinister_number" />
                                      <td
                                        v-text="
                                          numberFormat(item.deducible_amount)
                                        "
                                      />
                                      <td v-text="item.coinsurance" />
                                      <td>
                                        <v-icon
                                          small
                                          :color="item.bill ? 'success' : ''"
                                        >
                                          mdi-{{
                                            item.bill ? "check" : "close"
                                          }}
                                        </v-icon>
                                      </td>
                                      <td v-text="numberFormat(item.amount)" />
                                      <td>
                                        <v-tooltip v-if="item.comment" left>
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              v-on="on"
                                              fab
                                              dark
                                              x-small
                                              class="ml-1"
                                              color="info"
                                              @click="commentDialog(item)"
                                            >
                                              <v-icon>
                                                mdi-message-draw
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <span
                                            v-text="'Seg. médico comentario'"
                                          />
                                        </v-tooltip>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td>
                                        <b v-text="numberFormat(icd.amount)" />
                                      </td>
                                      <td />
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
                <v-col cols="12">
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="'DATOS'" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="4">
                          <v-autocomplete
                            label="Admisión*"
                            v-model="data.letter_type_id"
                            :rules="rules.required"
                            :items="letter_types"
                            item-value="id"
                            :item-text="(item) => item.name"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <v-autocomplete
                            label="Resultado del dictamen / Tipo de pago**"
                            v-model="data.amount_response_id"
                            :rules="rules.required"
                            :items="amount_responses"
                            item-value="id"
                            :item-text="(item) => item.rha_amount_response"
                            :loading="amount_responses_loading"
                            dense
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          xs="12"
                          md="4"
                          v-if="data.amount_response_id == 1"
                        >
                          <v-text-field
                            label="Monto amparado / Hospitalización*"
                            v-model="data.authorized_amount"
                            type="number"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <v-switch
                            class="mt-0"
                            :label="'Médico de red o se ajusta al tabulador'"
                            v-model="data.doctor_adjust"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            label="Accidente / Enfermedad*"
                            v-model="data.accident_id"
                            :rules="rules.required"
                            :items="accident_types"
                            item-value="id"
                            :item-text="(item) => item.name"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            label="Tipo siniestro*"
                            v-model="data.sinister_id"
                            :rules="rules.required"
                            :items="sinister_types"
                            item-value="id"
                            :item-text="(item) => item.name"
                            dense
                          />
                        </v-col>
                        <v-col
                          v-if="
                            data.sinister_id == 2 ||
                            (data.sinister_id == 1 &&
                              !rha.insurance.auto_sinister_number)
                          "
                          cols="12"
                          xs="12"
                          md="3"
                        >
                          <v-text-field
                            label="Número de siniestro"
                            v-model="data.sinister_number"
                            :rules="rules.text15"
                            type="text"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <v-text-field
                            label="Deducible*"
                            v-model="data.amount_deductible"
                            :rules="rules.required"
                            type="number"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <v-text-field
                            label="Coaseguro %*"
                            v-model="data.percentage_coinsurance"
                            :rules="rules.required"
                            type="number"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <v-text-field
                            label="Coaseguro HM %*"
                            v-model="data.fee_coinsurance"
                            :rules="rules.required"
                            type="number"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <v-text-field
                            label="Tope de coaseguro*"
                            v-model="data.max_coinsurance"
                            :rules="rules.required"
                            type="number"
                            dense
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <DatePicker
                            label="F. Diagnóstico"
                            :model.sync="data.icd_date"
                            :rules="rules.required"
                            clean
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="3">
                          <DatePicker
                            label="F. inicio padecimineto"
                            :model.sync="data.cpt_date"
                            :rules="rules.required"
                            clean
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            label="Observaciones"
                            v-model="data.caption"
                            rows="3"
                            dense
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            label="Procedimiento definitivo (CPT GRAL.)"
                            v-model="data.rha_cpt_id"
                            :items="cpts_slct"
                            item-value="id"
                            :item-text="(v) => `${v.code} | ${v.cpt}`"
                            :loading="cpts_slct_ldg"
                            :rules="rules.required"
                            dense
                            @change="cptIsBlocked"
                          >
                            <template v-slot:selection="{ item }">
                              <div class="text-caption d-flex flex-column">
                                {{ `${item.code} | ${item.cpt}` }}
                              </div>
                            </template>
                            <template v-slot:item="{ item }">
                              <div class="text-caption d-flex flex-column">
                                {{ `${item.code} | ${item.cpt}` }}
                              </div>
                            </template>
                          </v-autocomplete>
                          <div v-if="cpt_blocked" class="red--text">
                            {{ cpt_blocked.observation }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-form>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card class="mx-auto" :disabled="doctor_loading">
                  <v-card-title>
                    <v-row dense class="align-center">
                      <v-col cols="8">
                        <h2 class="text-caption">
                          <b>TOTAL AUTORIZADO </b>
                          <br />
                          {{
                            data.amount_response_id == 1
                              ? total_authorized
                              : "N/A"
                          }}
                        </h2>
                        <h2 class="text-caption">
                          <b>HONORARIOS MÉDICOS </b>
                          <br />
                          {{ doctors_total_amount }}
                        </h2>
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <v-btn
                          dark
                          x-small
                          @click="doctors_resume_dialog = true"
                          v-text="'VER RESUMEN'"
                        />
                        <v-btn
                          dark
                          x-small
                          @click="doctorLetterDlg"
                          color="warning"
                          class="ml-1"
                        >
                          Agregar
                          <v-icon small> mdi-plus </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th style="width: 20px" v-text="'#'" />
                                <th style="width: 100px" v-text="'IDM'" />
                                <th v-text="'Médico'" />
                                <th
                                  style="width: 100px"
                                  v-text="'Especialidad'"
                                />
                                <th style="width: 100px" v-text="'Tipo'" />
                                <th style="width: 100px" v-text="'CPT'" />
                                <th style="width: 100px" v-text="'Monto'" />
                                <th style="width: 100px" v-text="'Visitas'" />
                                <th
                                  style="width: 100px"
                                  v-text="'Monto total'"
                                />
                                <th style="width: 20px" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in data.doctors"
                                :key="index"
                                v-if="item.active"
                              >
                                <td v-text="`${index + 1}`" />
                                <td
                                  v-text="item.doctor_id ? item.doctor_id : '-'"
                                />
                                <td v-text="item.full_name" />
                                <td
                                  v-text="
                                    item.specialty_type
                                      ? item.specialty_type.specialty_type
                                      : 'PEND.'
                                  "
                                />
                                <td v-text="item.type.name" />
                                <td
                                  v-text="`${item.cpt.code} | ${item.cpt.cpt}`"
                                />
                                <td v-text="`$${item.amount}`" />
                                <td
                                  v-text="
                                    item.type.id > 3 ? 'N/A' : item.visits
                                  "
                                />
                                <td v-text="`$${item.total_amount}`" />
                                <td>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        text
                                        icon
                                        x-small
                                        color="info"
                                        @click="
                                          doctorLetterVisitsDlg(item, index)
                                        "
                                      >
                                        <v-icon medium> mdi-pencil </v-icon>
                                      </v-btn>
                                    </template>
                                    <span
                                      v-text="`Editar honorario (${index + 1})`"
                                    />
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        text
                                        icon
                                        x-small
                                        color="error"
                                        @click="doctorLetterRemove(index)"
                                      >
                                        <v-icon medium> mdi-close </v-icon>
                                      </v-btn>
                                    </template>
                                    <span
                                      v-text="
                                        `Eliminar honorario (${index + 1})`
                                      "
                                    />
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" v-if="data.doctors.length == 0">
                        <p class="text-caption text-center">SIN REGISTROS</p>
                      </v-col>
                      <!-- <v-col cols="12" class="mb-5">
                        <hr />
                      </v-col>
                      <v-col cols="12" class="mt-4">
                        <v-form
                          v-on:submit.prevent
                          ref="doctor_form"
                          lazy-validation
                        >
                          <v-row dense>
                            <v-col cols="12" xs="12" md="3">
                              <v-autocomplete
                                label="Tipo de médico*"
                                v-model="doctor.type"
                                :rules="rules.required"
                                :items="doctor_types"
                                item-value="id"
                                :item-text="(item) => item.name"
                                dense
                                return-object
                                @change="doctorVisits(true)"
                                :loading="doctor_types_ldg"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <v-text-field
                                label="Nombre del Médico (Presiona enter para buscar)*"
                                v-model="doctor.name"
                                :rules="rules.text50Required"
                                v-on:keyup.enter="doctorSearch"
                                type="text"
                                dense
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <v-text-field
                                label="Apellido paterno*"
                                v-model="doctor.first_surname"
                                :rules="rules.text25Required"
                                v-on:keyup.enter="doctorSearch"
                                type="text"
                                dense
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <v-text-field
                                label="Apellido materno"
                                v-model="doctor.second_surname"
                                :rules="rules.text25"
                                v-on:keyup.enter="doctorSearch"
                                type="text"
                                dense
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="9">
                              <v-autocomplete
                                label="Especialidad*"
                                v-model="doctor.specialty_type"
                                :rules="rules.required"
                                :items="specialty_types"
                                item-value="id"
                                :item-text="(item) => item.specialty_type"
                                :loading="specialty_types_loading"
                                return-object
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              xs="12"
                              md="3"
                              v-if="doctor_visits_show"
                            >
                              <v-text-field
                                label="Visitas*"
                                v-model="doctor.visits"
                                :rules="rules.required"
                                type="number"
                                dense
                                @change="doctorAmountCalc"
                                @keyup="doctorAmountCalc"
                              />
                            </v-col>
                            <v-col cols="12" class="pb-3">
                              <v-row dense>
                                <v-col cols="12">
                                  <v-autocomplete
                                    label="Procedimiento definitivo (CPT)"
                                    v-model="doctor.cpt"
                                    :rules="rules.required"
                                    :items="cpts_slct"
                                    item-value="id"
                                    :item-text="
                                      (item) => `${item.code} | ${item.cpt}`
                                    "
                                    :loading="cpts_slct_ldg"
                                    return-object
                                    dense
                                    @change="doctorAmountCalc"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <div
                                        class="text-caption d-flex flex-column"
                                      >
                                        {{ `${item.code} | ${item.cpt}` }}
                                      </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                      <div
                                        class="text-caption d-flex flex-column"
                                      >
                                        {{ `${item.code} | ${item.cpt}` }}
                                      </div>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-btn
                                    color="info"
                                    x-small
                                    dark
                                    @click="cptsDlg"
                                  >
                                    Buscador CPT
                                    <v-icon right> mdi-database-search </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <v-text-field
                                label="Monto*"
                                v-model="doctor.amount"
                                :rules="rules.required"
                                type="number"
                                @change="doctorAmountModify"
                                @keyup="doctorAmountModify"
                                dense
                              />
                            </v-col>
                            <v-col cols="12">
                              <p class="text-center text-subtitle-1">
                                Monto total:
                                <b v-text="`$${doctor.total_amount}`" />
                              </p>
                            </v-col>
                            <v-col cols="12" v-if="!doctor_edit">
                              <v-btn
                                color="warning"
                                :loading="doctor_loading"
                                small
                                block
                                dark
                                @click="doctorAdd"
                              >
                                <v-icon left v-text="'mdi-plus'" />
                                Agregar Honorario
                              </v-btn>
                            </v-col>
                            <v-col cols="12" xs="6" md="6" v-if="doctor_edit">
                              <v-btn
                                color="secondary"
                                :loading="doctor_loading"
                                x-small
                                block
                                dark
                                @click="doctorEditCancel"
                              >
                                <v-icon left v-text="'mdi-close'" />
                                Cancelar
                              </v-btn>
                            </v-col>
                            <v-col cols="12" xs="6" md="6" v-if="doctor_edit">
                              <v-btn
                                color="info"
                                :loading="doctor_loading"
                                x-small
                                block
                                dark
                                @click="doctorEdit"
                              >
                                <v-icon left v-text="'mdi-pencil'" />
                                Editar honorario
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col> -->
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                v-if="cpt_blocked == null || !cpt_blocked.block_payment"
                cols="12"
                class="text-right"
              >
                <BtnCircle
                  :tLabel="form_store ? 'Agregar' : 'Editar'"
                  tPosition="top"
                  :icon="form_store ? 'mdi-plus' : 'mdi-pencil'"
                  :color="form_store ? 'success' : 'info'"
                  size="small"
                  :click="formSubmit"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG FORM end -->
    <!-- DIALOG RESUME DOCTORS start-->
    <v-dialog
      v-model="doctors_resume_dialog"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Resumen de médicos'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="doctors_resume_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Médico'" />
                      <th v-text="'Especialidad'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Visitas'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in doctors_resume" :key="i">
                      <td v-text="`${i + 1}`" />
                      <td v-text="item.name" />
                      <td v-text="item.speciality" />
                      <td v-text="item.type_name" />
                      <td v-text="item.type_id > 3 ? 'N/A' : item.visits" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG RESUME DOCTORS end-->
    <!-- DIALOG SEARCH DOCTORS start-->
    <v-dialog v-model="doctors_dialog" scrollable persistent max-width="1000px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Busqueda de médicos'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="doctors_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'IDM'" />
                      <th v-text="'Médico'" />
                      <th v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in doctors" :key="i">
                      <td v-text="`${i + 1}`" />
                      <td v-text="item.id" />
                      <td
                        v-text="
                          `${item.name} ${item.first_surname} ${item.second_surname}`
                        "
                      />
                      <td>
                        <v-tooltip bottom v-if="!doctor_edit">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="warning"
                              @click="doctorSelected(i)"
                            >
                              <v-icon medium v-text="'mdi-check'" />
                            </v-btn>
                          </template>
                          <span v-text="`Seleccionar médico (${i + 1})`" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG SEARCH DOCTORS end-->
    <!-- DIALOG DETAIL start-->
    <v-dialog
      v-model="detail_dialog"
      scrollable
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title>
            {{
              `${$route.meta.title} | ${
                last_letter_btn ? "Definitiva" : "Detalle"
              }`
            }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="detail_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="detail_dialog">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'INFORMACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="4">
                        <ViewData
                          label="Hospital"
                          :value="rha.provider.trade_name"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <ViewData
                          label="Paciente"
                          :value="
                            rha.dependent_name
                              ? rha.dependent_name +
                                ' ' +
                                rha.dependent_first_surname +
                                ' ' +
                                rha.dependent_second_surname
                              : rha.name +
                                ' ' +
                                rha.first_surname +
                                ' ' +
                                rha.second_surname
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <ViewData
                          label="Titular de Póliza"
                          :value="
                            rha.name +
                            ' ' +
                            rha.first_surname +
                            ' ' +
                            rha.second_surname
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Tipo asegurado"
                          :class="`text-caption${
                            !rha.insured_id
                              ? ' orange--text text--darken-3'
                              : ''
                          }`"
                          :value="
                            !rha.insured_id
                              ? rha.policyholder
                                ? 'TITULAR'
                                : 'DEPENDIENTE'
                              : rha.insured.insured_type.insured_type
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData label="Contratante" :value="rha.contractor" />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="No. poliza"
                          :value="rha.insured.policy"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData label="ID" :value="rha.enrollment" />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData label="Oficina" value="CENTRO" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2
                      class="text-caption"
                      v-text="'DIAGNOSTICO DEFINITIVO (ICD)'"
                    />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'Código'" />
                                <th v-text="'Descripción'" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, i) in data.icds" :key="i">
                                <td v-text="`${i + 1}`" />
                                <td v-text="item.code" />
                                <td v-text="item.icd" />
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DATOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="4">
                        <ViewData
                          label="Admisión"
                          :value="
                            data.letter_type_id == 1
                              ? 'PROGRAMACIÓN DE CIRUGÍA'
                              : 'REPORTE HOSPITALARIO'
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <ViewData
                          label="Resultado del dictamen / Tipo de pago"
                          :value="
                            amount_responses[data.amount_response_id - 1]
                              .rha_amount_response
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="4"
                        v-if="data.amount_response_id == 1"
                      >
                        <ViewData
                          label="Monto amparado / Hospitalización"
                          :value="data.authorized_amount"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Médico de red o se ajusta al tabulador"
                          :value="data.doctor_adjust ? 'SI' : 'NO'"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Accidente / Enfermedad"
                          :value="
                            data.accident_id == 1 ? 'ACCIDENTE' : 'ENFERMEDAD'
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="Tipo siniestro"
                          :value="
                            data.sinister_id == 1 ? 'INICIAL' : 'COMPLEMENTO'
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Número de siniestro"
                          :value="data.sinister_number"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Deducible"
                          :value="data.amount_deductible"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Coaseguro %"
                          :value="data.percentage_coinsurance"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Coaseguro HM %"
                          :value="data.fee_coinsurance"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Tope deducible y coaseguro"
                          :value="data.max_coinsurance"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="F. diagnóstico"
                          :value="data.icd_date"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <ViewData
                          label="F. inicio padecimiento"
                          :value="data.cpt_date"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <ViewData label="Observaciones" :value="data.caption" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <v-row dense class="align-center">
                      <v-col v-if="!last_letter_action" cols="12">
                        <v-alert dense border="left" type="warning">
                          Médicos sin registro en base de datos
                        </v-alert>
                      </v-col>
                      <v-col cols="10">
                        <h2 class="text-caption">
                          <b>TOTAL AUTORIZADO </b>
                          <br />
                          {{
                            data.amount_response_id == 1
                              ? total_authorized
                              : "N/A"
                          }}
                        </h2>
                        <h2 class="text-caption">
                          <b>HONORARIOS MÉDICOS </b>
                          <br />
                          {{ doctors_total_amount }}
                        </h2>
                      </v-col>
                      <v-col cols="2" class="text-right">
                        <v-btn
                          dark
                          x-small
                          @click="doctors_resume_dialog = true"
                          v-text="'VER RESUMEN'"
                        />
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'IDM'" />
                                <th v-text="'Médico'" />
                                <th v-text="'Especialidad'" />
                                <th v-text="'Tipo'" />
                                <th v-text="'CPT'" />
                                <th v-text="'Monto'" />
                                <th v-text="'Visitas'" />
                                <th v-text="'Monto total'" />
                                <th v-if="last_letter_btn" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, i) in data.doctors" :key="i">
                                <td v-text="`${i + 1}`" />
                                <td
                                  v-text="item.doctor_id ? item.doctor_id : '-'"
                                />
                                <td
                                  v-text="
                                    item.name +
                                    ' ' +
                                    item.first_surname +
                                    ' ' +
                                    item.second_surname
                                  "
                                />
                                <td
                                  v-text="item.specialty_type.specialty_type"
                                />
                                <td v-text="item.type.name" />
                                <td
                                  v-text="`${item.cpt.code} | ${item.cpt.cpt}`"
                                />
                                <td v-text="`$${item.amount}`" />
                                <td
                                  v-text="
                                    item.type.id > 3 ? 'N/A' : item.visits
                                  "
                                />
                                <td v-text="`$${item.total_amount}`" />
                                <td v-if="last_letter_btn">
                                  <v-tooltip v-if="!item.doctor_id" left>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        class="mr-1"
                                        v-on="on"
                                        fab
                                        x-small
                                        color="warning"
                                        @click.prevent="
                                          doctorSearchDialog(item, i)
                                        "
                                      >
                                        <v-icon> mdi-file-find </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="'Asignar médico'" />
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-tooltip
                  top
                  v-if="
                    login.permissions.rha_letters.delete && !last_letter_btn
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      color="error"
                      @click="detailDesactivate(data.id)"
                      :loading="loading"
                    >
                      <v-icon v-text="'mdi-delete'" />
                    </v-btn>
                  </template>
                  <span v-text="'Eliminar'" />
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="text-right">
                <v-tooltip
                  top
                  v-if="login.permissions.rha_letters.update && last_letter_btn"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      color="warning"
                      @click="lastLetter(data)"
                      :loading="loading"
                      :disabled="!last_letter_action"
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Aceptar'" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="history_reviews_dialog"
      scrollable
      persistent
      max-width="900px"
    >
      <v-card
        tile
        v-if="history_reviews"
        :loading="history_reviews_dialog_loading"
        :disabled="history_reviews_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Historial de revisiones`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="history_reviews_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'Detalle'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_payment_edit"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>Revisión</th>
                                <th>Usuario</th>
                                <th>Fecha</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(history_review, i) in history_reviews"
                                :key="i"
                              >
                                <td v-text="history_review.review" />
                                <td v-text="history_review.created_by.email" />
                                <td v-text="history_review.created_at" />
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG DETAIL end -->
    <!-- DIALOG SERCH DOCTOR start -->
    <v-dialog
      v-model="doctor_search_dialog"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card
        tile
        :disabled="doctor_search_dialog_loading"
        :loading="doctor_search_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_search_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_search_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'MÉDICO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="doctor_search_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3" class="mt-3">
                        <v-text-field
                          label="Nombre*"
                          v-model="doctor_search_data.doctor.name"
                          type="text"
                          maxlength="50"
                          :rules="rules.required"
                          counter
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3" class="mt-3">
                        <v-text-field
                          label="Apellido paterno*"
                          v-model="doctor_search_data.doctor.first_surname"
                          type="text"
                          maxlength="25"
                          :rules="rules.required"
                          counter
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3" class="mt-3">
                        <v-text-field
                          label="Apellido materno"
                          v-model="doctor_search_data.doctor.second_surname"
                          type="text"
                          maxlength="25"
                          counter
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Especialidad"
                          :value="
                            doctor_search_data.doctor.specialty_type
                              .specialty_type
                          "
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="info"
                          small
                          @click.prevent="doctorSearchAction"
                        >
                          BUSCAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="doctor_search_data.find == false" cols="12">
              <v-alert type="warning" class="mb-0" dense>
                No se encontraron coincidencias con los parametros de busqueda
              </v-alert>
            </v-col>
            <v-col v-if="doctor_search_data.find" cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `RESULTADOS (${doctor_search_data.doctors.length})`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-text="'#'" />
                              <th v-text="'IDM'" />
                              <th v-text="'Médico'" />
                              <th v-text="'Especialidad'" />
                              <th v-text="''" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(doctor, i) in doctor_search_data.doctors"
                              :key="i"
                            >
                              <td v-text="`${i + 1}`" />
                              <td v-text="doctor.id" />
                              <td
                                v-text="
                                  `${doctor.name} ${doctor.first_surname} ${doctor.second_surname}`
                                "
                              />
                              <td v-text="doctor.specialty_type_name" />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      text
                                      icon
                                      x-small
                                      color="warning"
                                      @click="doctorSearchSelected(doctor)"
                                    >
                                      <v-icon medium> mdi-check </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="`Seleccionar médico (${i + 1})`"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="comment_dialog" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rha_letter_comment_dlg"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        v-if="rha_letter_comment"
        tile
        :disabled="rha_letter_comment_dlg_ldg"
        :loading="rha_letter_comment_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> ANÁLISIS DICTAMEN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_letter_comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form
            v-on:submit.prevent
            ref="rha_letter_comment_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-spacer />
              </v-col>
              <v-col cols="12">
                <ViewData
                  v-if="rha_letter_comment.reg"
                  label="ICD ampliado"
                  :value="rha_letter_comment.icd_extended"
                />
                <v-textarea
                  v-else
                  label="ICD ampliado"
                  v-model="rha_letter_comment.icd_extended"
                  rows="3"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <ViewData
                  v-if="rha_letter_comment.reg"
                  label="Estudios que corroboran el diagnóstico"
                  :value="rha_letter_comment.studies"
                />
                <v-textarea
                  v-else
                  label="Estudios que corroboran el diagnóstico"
                  v-model="rha_letter_comment.studies"
                  rows="3"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <ViewData
                  v-if="rha_letter_comment.reg"
                  label="Conclusión diagnostica"
                  :value="rha_letter_comment.comment"
                />
                <v-textarea
                  v-else
                  label="Conclusión diagnostica"
                  v-model="rha_letter_comment.comment"
                  rows="3"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <ViewData
                  v-if="rha_letter_comment.reg"
                  label="Observaciones"
                  :value="rha_letter_comment.observation"
                />
                <v-textarea
                  v-else
                  label="Observaciones"
                  v-model="rha_letter_comment.observation"
                  rows="5"
                  dense
                />
              </v-col>
              <v-col v-if="!rha_letter_comment.reg" cols="12">
                <v-btn
                  dark
                  small
                  block
                  color="warning"
                  @click="rhaLetterComment"
                >
                  Enviar
                  <v-icon right> mdi-send </v-icon>
                </v-btn>
              </v-col>
              <v-col v-else cols="12">
                <v-btn
                  dark
                  small
                  color="info"
                  @click="rha_letter_comment.reg = false"
                >
                  Editar
                  <v-icon right> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assesment_rep_dlg"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :disabled="assesment_rep_dlg_ldg"
        :loading="assesment_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> VALORACIÓN DICTAMEN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="assesment_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="assesment_rep">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <table class="border">
                <tbody>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'NÚMERO DE SINIESTRO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.sinister_number"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'NÚMERO DE PÓLIZA: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.policy"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'NÚMERO DE RECLAMACIÓN: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.claim_number"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2"><b v-text="'TITULAR: '" /></td>
                    <td
                      class="border py-2 px-2"
                      v-text="
                        assesment_rep.policyholder +
                        ' (' +
                        assesment_rep.policyholder_antiquity +
                        ')'
                      "
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'ASEGURADO AFECTADO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="
                        assesment_rep.insured +
                        ' (' +
                        assesment_rep.insured_antiquity +
                        ')'
                      "
                    />
                  </tr>
                  <!-- <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'ANTIGUEDAD: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.antiquity"
                    />
                  </tr> -->
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'SUMA ASEGURADA: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="numberFormat(assesment_rep.assured_amount)"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'COASEGURO TOPADO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="numberFormat(assesment_rep.max_coinsurance)"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'HOSPITAL: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.trade_name"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'ENTIDAD FEDERATIVA: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.entity"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'CIUDAD: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.municipality"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'PORCENTAJE POR ENTIDAD FEDERATIVA: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.percent + '%'"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'MÉDICO TRATANTE: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.doctor"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'MÉDICO DE RED O SE AJUSTA AL TABULADOR: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.doctor_adjust"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'MONTO DE HONORARIOS: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="
                        numberFormat(assesment_rep.rha_letter_doctors_amount)
                      "
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'DEDUCIBLE A APLICAR: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="numberFormat(assesment_rep.amount_deductible)"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'COASEGURO A APLICAR: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.percentage_coinsurance + '%'"
                    />
                  </tr>
                  <tr>
                    <td v-text="'-------'" />
                    <td />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'ACCIDENTE O ENFERMEDAD: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.accident"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'F. INICIO PADECIMIENTO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.cpt_date"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'F. DIAGNÓSTICO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.icd_date"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'DIAGNÓSTICO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="
                        'ICD ' +
                        assesment_rep.icd_code +
                        ' | ' +
                        assesment_rep.icd
                      "
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="''" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.icd_extended"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'ESTUDIOS QUE CORROBORAN EL DIAGNÓSTICO: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.studies"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'CONCLUSIÓN DIAGNOSTICA: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.comment"
                    />
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'TRATAMIENTO A REALIZAR: '" />
                    </td>
                    <td class="border py-2 px-2">
                      <div
                        v-for="(
                          rha_letter_doctor, i
                        ) in assesment_rep.rha_letter_doctors"
                        :key="i"
                      >
                        {{ rha_letter_doctor.cpt.code }} |
                        {{ rha_letter_doctor.cpt.cpt }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="border py-2 px-2">
                      <b v-text="'OBSERVACIONES: '" />
                    </td>
                    <td
                      class="border py-2 px-2"
                      v-text="assesment_rep.observation"
                    />
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="doctors_type_dlg"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card v-if="doctors_type" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> MÉDICOS EN NOTAS DE EVOLUCIÓN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctors_type_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="40px">#</th>
                      <th>Nombre</th>
                      <th>Especialidad</th>
                      <th width="50px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in doctors_type" :key="i">
                      <td v-text="i + 1" />
                      <td>
                        <v-icon
                          small
                          class="mr-2"
                          :color="item.doctor_id ? 'success' : ''"
                        >
                          mdi-database-{{ item.doctor_id ? "check" : "alert" }}
                        </v-icon>
                        {{
                          item.doctor_name +
                          " " +
                          item.doctor_first_surname +
                          " " +
                          item.doctor_second_surname
                        }}
                      </td>
                      <td v-text="item.specialty_type.specialty_type"></td>
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              x-small
                              color="success"
                              @click="setDoctorVals(item)"
                            >
                              <v-icon small> mdi-send </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="doctor_letter_dlg"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        :loading="doctor_letter_dlg_ldg"
        :disabled="doctor_letter_dlg_ldg"
        tile
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Médico </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_letter_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_letter">
          <v-row dense class="pt-4">
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Nombre"
                v-model="doctor_letter.name"
                type="text"
                dense
                :disabled="doctor_letter.doctors.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="2">
              <v-text-field
                label="Apellido paterno"
                v-model="doctor_letter.first_surname"
                type="text"
                dense
                :disabled="doctor_letter.doctors.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="2">
              <v-text-field
                label="Apellido materno"
                v-model="doctor_letter.second_surname"
                type="text"
                dense
                :disabled="doctor_letter.doctors.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-autocomplete
                label="Especialidad"
                v-model="doctor_letter.specialty_type_id"
                :items="specialty_types"
                item-value="id"
                :item-text="(item) => item.specialty_type"
                :loading="specialty_types_loading"
                dense
                :disabled="doctor_letter.doctors.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="2">
              <v-btn
                v-if="!doctor_letter.find || doctor_letter.doctors.length == 0"
                block
                small
                color="info"
                :disabled="
                  doctor_letter.name == '' &&
                  doctor_letter.first_surname == '' &&
                  doctor_letter.second_surname == '' &&
                  doctor_letter.specialty_type_id == null
                "
                @click.prevent="doctorLetterSearch"
              >
                Buscar
                <v-icon small right> mdi-magnify </v-icon>
              </v-btn>
              <v-btn
                v-else
                block
                small
                color=""
                @click.prevent="
                  doctor_letter.find = false;
                  doctor_letter.doctors = [];
                "
              >
                Cambiar busqueda
                <v-icon small right> mdi-refresh </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="doctor_letter.doctors.length > 0" dense>
            <v-col cols="12">
              <v-text-field
                v-model="doctor_letter.search"
                append-icon="mdi-magnify"
                label="Buscar..."
                single-line
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="doctor_letter_headers"
                :search="doctor_letter.search"
                :items="doctor_letter.doctors"
                :items-per-page="15"
                item-key="doctor_id"
                dense
              >
                <template v-slot:item.key="{ item, index }">
                  <b v-text="index + 1" />
                </template>
                <template v-slot:item.action="{ item }">
                  <div class="text-right">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          x-small
                          v-on="on"
                          color="warning"
                          @click="doctorLetterVisitsDlg(item, null)"
                          class="ml-1"
                        >
                          <v-icon> mdi-send </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Seleccionar'" />
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="doctor_letter.find" dense>
            <v-col cols="12">
              <v-btn
                color="error"
                x-small
                dark
                class="mr-1"
                @click="doctorLetterVisitsDlg(null, null)"
              >
                Agregar provisional
                <v-icon small right> mdi-send </v-icon>
              </v-btn>
              <v-btn color="warning" x-small dark @click="doctorNewDlg">
                Agregar médico
                <v-icon small right> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="doctor_letter_visits_dlg"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        :loading="doctor_letter_visits_dlg_ldg"
        :disabled="doctor_letter_visits_dlg_ldg"
        tile
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            Honorarios |
            {{
              doctor_letter_visits
                ? "IDM " +
                  doctor_letter_visits.doctor_id +
                  " | " +
                  doctor_letter_visits.full_name
                : ""
            }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_letter_visits_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_letter_visits">
          <v-form
            v-on:submit.prevent
            ref="doctor_letter_visits_form"
            lazy-validation
          >
            <v-row dense class="pt-4">
              <v-col cols="12" xs="12" md="3">
                <v-autocomplete
                  label="Tipo de médico*"
                  v-model="doctor_letter_visits.type"
                  :rules="rules.required"
                  :items="doctor_types"
                  item-value="id"
                  :item-text="(item) => item.name"
                  dense
                  return-object
                  @change="doctorLetterVisits(true)"
                  :loading="doctor_types_ldg"
                />
              </v-col>
              <v-col cols="12" xs="12" md="3" v-if="doctor_visits_show">
                <v-text-field
                  label="Visitas*"
                  v-model="doctor_letter_visits.visits"
                  :rules="rules.required"
                  type="number"
                  dense
                  @change="doctorLetterAmountCalc"
                  @keyup="doctorLetterAmountCalc"
                />
              </v-col>
              <v-col cols="12" class="pb-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Procedimiento definitivo (CPT)"
                      v-model="doctor_letter_visits.cpt"
                      :rules="rules.required"
                      :items="cpts_slct"
                      item-value="id"
                      :item-text="(item) => `${item.code} | ${item.cpt}`"
                      :loading="cpts_slct_ldg"
                      return-object
                      dense
                      @change="doctorLetterAmountCalc"
                    >
                      <template v-slot:selection="{ item }">
                        <div class="text-caption d-flex flex-column">
                          {{ `${item.code} | ${item.cpt}` }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div class="text-caption d-flex flex-column">
                          {{ `${item.code} | ${item.cpt}` }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="info" x-small dark @click="cptsDlg">
                      Buscador CPT
                      <v-icon right> mdi-database-search </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-text-field
                  label="Monto*"
                  v-model="doctor_letter_visits.amount"
                  :rules="rules.required"
                  type="number"
                  @change="doctorLetterAmountModify"
                  @keyup="doctorLetterAmountModify"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <p class="text-center text-subtitle-1">
                  Monto total:
                  <b v-text="`$${doctor_letter_visits.total_amount}`" />
                </p>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  :color="
                    doctor_letter_visits_index == null ? 'warning' : 'info'
                  "
                  small
                  @click.prevent="doctorLetterHandle"
                >
                  {{
                    doctor_letter_visits_index == null ? "Agregar" : "Editar"
                  }}
                  <v-icon right>
                    mdi-{{
                      doctor_letter_visits_index == null ? "plus" : "pencil"
                    }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cpts_dlg" scrollable persistent max-width="1100px">
      <v-card :loading="cpts_dlg_ldg" :disabled="cpts_dlg_ldg" tile>
        <v-toolbar dark dense>
          <v-toolbar-title>
            Procedimiento definitivo CPT
            {{ tbl_cpts.length > 0 ? " (" + tbl_cpts.length + ")" : "" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="cpts_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="cpt_search">
          <v-row dense class="pt-4">
            <v-col cols="12" sm="12" md="2">
              <v-text-field
                label="Código"
                v-model="cpt_search.code"
                type="text"
                dense
                :disabled="tbl_cpts.length > 0"
                v-on:keyup.enter="cptsSearch"
              />
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-text-field
                label="Descripción"
                v-model="cpt_search.cpt"
                type="text"
                dense
                :disabled="tbl_cpts.length > 0"
                v-on:keyup.enter="cptsSearch"
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-autocomplete
                label="Sistema"
                v-model="cpt_search.cpt_system_id"
                :items="cpt_systems"
                item-value="id"
                :item-text="(item) => item.cpt_system"
                :loading="cpt_systems_ldg"
                dense
                :disabled="tbl_cpts.length > 0"
              >
                <template v-slot:item="{ item }">
                  <div class="text-caption d-flex flex-column">
                    {{ item.cpt_system }}
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-autocomplete
                label="Concepto"
                v-model="cpt_search.cpt_specialty_id"
                :items="cpt_specialties"
                item-value="id"
                :item-text="(item) => item.cpt_specialty"
                :loading="cpt_specialties_ldg"
                dense
                :disabled="tbl_cpts.length > 0"
              >
                <template v-slot:item="{ item }">
                  <div class="text-caption d-flex flex-column">
                    {{ item.cpt_specialty }}
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-btn
                v-if="tbl_cpts.length == 0"
                color="info"
                small
                block
                @click.prevent="cptsSearch"
                :disabled="
                  !cpt_search.cpt_system_id &&
                  !cpt_search.cpt_specialty_id &&
                  cpt_search.code == '' &&
                  cpt_search.cpt == ''
                "
              >
                Buscar
                <v-icon right> mdi-magnify </v-icon>
              </v-btn>
              <v-btn v-else small block @click.prevent="tbl_cpts = []">
                Cambiar busqueda
                <v-icon small right> mdi-refresh </v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="tbl_cpts.length > 0" cols="12" xs="12" md="12">
              <v-text-field
                v-model="tbl_cpts_srch"
                append-icon="mdi-magnify"
                label="Buscar en tabla..."
                single-line
                hide-details
                dense
              />
            </v-col>
            <v-col v-if="tbl_cpts.length > 0" cols="12">
              <v-data-table
                :headers="tbl_cpts_hdrs"
                :items="tbl_cpts"
                :search="tbl_cpts_srch"
              >
                <template v-slot:item.key="{ item }">
                  <b v-text="item.key" />
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        fab
                        x-small
                        color="success"
                        @click="cptsSelect(item)"
                      >
                        <v-icon> mdi-send </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Seleccionar'" />
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="doctor_new_dlg" scrollable persistent max-width="1200">
      <v-card tile :disabled="doctor_new_dlg_ldg" :loading="doctor_new_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> Médico </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_new_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_new">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DATOS'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="doctor_new_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Nombre*"
                          v-model="doctor_new.name"
                          type="text"
                          :rules="rules.textRequired"
                          maxlength="50"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="A. Paterno*"
                          v-model="doctor_new.first_surname"
                          type="text"
                          :rules="rules.textRequired"
                          maxlength="25"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="A. Materno"
                          v-model="doctor_new.second_surname"
                          type="text"
                          :rules="rules.text"
                          maxlength="25"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Correo electrónico"
                          v-model="doctor_new.email"
                          type="text"
                          :rules="rules.emailNoRequired"
                          maxlength="50"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Teléfono"
                          v-model="doctor_new.phone"
                          type="text"
                          maxlength="10"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="RFC"
                          v-model="doctor_new.code_tax"
                          type="text"
                          maxlength="13"
                          dense
                          counter
                          :rules="rules.code_tax_sat_opt"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Consultorio | Hospital*"
                          v-model="doctor_new.provider_id"
                          :items="providers"
                          item-value="id"
                          :item-text="(v) => v.trade_name"
                          :loading="providers_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Especialidad*"
                          v-model="doctor_new.specialty_type_id"
                          :items="specialty_types"
                          item-value="id"
                          :item-text="(v) => v.specialty_type"
                          :loading="specialty_types_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Cédula"
                          v-model="doctor_new.license"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="warning"
                          small
                          @click.prevent="doctorNewHandle"
                        >
                          Agregar
                          <v-icon small right> mdi-plus </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG SERCH DOCTOR end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_PDF,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RhaData from "../../components/RhaData.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RhaData,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      login: this.$store.getters.getLogin,
      rha_id: this.$route.params.id,
      rha: { insurance: {}, contractor_id: 0, insured_id: 0 },
      table_loading: true,
      table_data: null,
      url_pdf: URL_PDF,
      table_data_total: 0,
      table_headers: [
        {
          text: "Fecha",
          filterable: true,
          value: "created_at",
        },
        {
          text: "Admisión",
          filterable: true,
          value: "admision",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "is_express",
        },
        {
          text: "Revisión",
          filterable: true,
          value: "reviews",
        },
        { text: "Acciones", value: "action", sortable: false },
      ],
      table_search: "",
      data: {},
      loading: false,
      rules: rules(),
      form_store: true,
      form_dialog: false,
      icds: [],
      icds_loading: true,
      icd_loading: false,
      icd: {},
      letter_types: [
        {
          id: 1,
          name: "PROGRAMACIÓN DE CIRUGÍA",
        },
        {
          id: 2,
          name: "REPORTE HOSPITALARIO",
        },
      ],
      amount_responses: [],
      amount_responses_loading: true,
      accident_types: [
        {
          id: 1,
          name: "ACCIDENTE",
        },
        {
          id: 2,
          name: "ENFERMEDAD",
        },
        {
          id: 3,
          name: "PARTO O CESÁREA",
        },
      ],
      sinister_types: [
        {
          id: 1,
          name: "INICIAL",
        },
        {
          id: 2,
          name: "COMPLEMENTO",
        },
      ],
      doctor_loading: false,
      doctor: {
        id: null,
        name: "",
        first_surname: "",
        second_surname: "",
        specialty_type: null,
        type: null,
        cpt: null,
        visits: "1",
        amount: "0",
        total_amount: 0,
      },
      doctors_resume: {},
      doctors_resume_dialog: false,
      insured_dialog: true,
      doctors: {},
      doctors_dialog: false,
      specialty_types: [],
      specialty_types_loading: true,
      doctor_types: [],
      doctor_types_ldg: true,
      doctor_visits_show: false,
      doctor_edit_row: 0,
      doctor_edit: false,
      detail_dialog: false,
      doctors_total_amount: 0,
      total_authorized: 0,
      history_reviews: null,
      history_reviews_dialog: false,
      history_reviews_dialog_loading: false,
      last_letter_id: null,
      last_letter_doctors: 0,
      last_letter_btn: true,
      last_letter_action: false,
      doctor_search_data: null,
      doctor_search_dialog: false,
      doctor_search_dialog_loading: false,
      sinister_rep: null,
      comment: null,
      comment_dialog: false,
      rha_letter_comment_dlg: false,
      rha_letter_comment_dlg_ldg: false,
      rha_letter_comment: null,
      assesment_rep: null,
      assesment_rep_dlg: false,
      assesment_rep_dlg_ldg: false,
      // doctor_types_ldg: false,
      doctors_type: [],
      doctors_type_dlg: false,
      cpts_slct: [],
      cpts_slct_ldg: false,
      cpts_dlg: false,
      cpts_dlg_ldg: false,
      cpt_systems: [],
      cpt_systems_ldg: true,
      cpt_specialties: [],
      cpt_specialties_ldg: true,
      cpt_search: null,
      tbl_cpts: [],
      tbl_cpts_hdrs: [
        { text: "#", filterable: false, value: "key" },
        { text: "Código", filterable: true, value: "code" },
        { text: "Descripción", filterable: true, value: "cpt" },
        { text: "Sistema", filterable: true, value: "cpt_system.cpt_system" },
        {
          text: "Concepto",
          filterable: true,
          value: "cpt_specialty.cpt_specialty",
        },
        { text: "", sortable: false, value: "action" },
      ],
      tbl_cpts_srch: "",
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      doctor_letter: null,
      doctor_letter_dlg: false,
      doctor_letter_dlg_ldg: false,
      doctor_letter_headers: [
        { text: "#", filterable: false, value: "key", width: "60" },
        { text: "IDM", filterable: true, value: "id" },
        { text: "Nombre", filterable: true, value: "full_name" },
        { text: "Municipio", filterable: true, value: "municipality" },
        { text: "Entidad", filterable: true, value: "entity" },
        { text: "Dirección", filterable: true, value: "address" },
        {
          text: "Especialidad",
          filterable: true,
          value: "specialty_type_name",
        },
        { text: "", sortable: false, value: "action", width: "60" },
      ],
      doctor_letter_visits: null,
      doctor_letter_visits_dlg: false,
      doctor_letter_visits_dlg_ldg: false,
      doctor_letter_visits_index: null,
      doctor_new: null,
      doctor_new_dlg: false,
      doctor_new_dlg_ldg: false,
      providers: [],
      providers_loading: true,
      cpt_blocked: null,
    };
  },
  methods: {
    init() {
      show("rha", this.login.token, this.rha_id).then((response) => {
        this.rha = response;
        this.dataDefaultValues();
        this.tableData();
      });
    },
    tableData() {
      this.table_loading = true;
      this.last_letter_id = null;
      this.last_letter_doctors = 0;

      index("rha/letters", this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.table_data = response.data;
        this.table_data_total = this.table_data.length;

        if (this.table_data_total > 0) {
          let item = this.table_data[0];
          if (item.review > this.rha.contractor_detail.letters_review) {
            this.last_letter_id = item.id;

            show("rha/letters", this.login.token, this.last_letter_id).then(
              (resp) => {
                this.last_letter_doctors = resp.doctors.length;
              }
            );
          }
        }

        this.table_loading = false;
      });
    },
    dataDefaultValues() {
      this.data = {
        rha_id: this.rha_id,
        icds: [],
        letter_type_id: 0,
        amount_response_id: 0,
        authorized_amount: 0,
        doctor_adjust: null,
        icd_date: null,
        cpt_date: null,
        accident_id: 0,
        sinister_id: 0,
        sinister_number: this.rha.sinister_number,
        amount_deductible: this.rha.contractor_detail
          ? this.rha.contractor_detail.amount_deductible
          : "0", //DEBE DE VENIR DE contractors
        percentage_coinsurance: this.rha.contractor_detail
          ? this.rha.contractor_detail.percentage_coinsurance
          : "0", //DEBE DE VENIR DE contractors
        fee_coinsurance: this.rha.contractor_detail
          ? this.rha.contractor_detail.fee_coinsurance
          : "0", //DEBE DE VENIR DE contractors
        max_coinsurance: this.rha.contractor_detail
          ? this.rha.contractor_detail.max_coinsurance
          : "0", //DEBE DE VENIR DE contractors
        caption:
          (this.rha.contractor_detail.caption
            ? this.rha.contractor_detail.caption + "\n\n"
            : "") + this.rha.insurance.caption, //DEBE DE VENIR DE contractors
        doctors: [],
        rha_cpt_id: null,
      };
    },
    formShow(id) {
      if (id) {
        this.detailShow(id, true);
      } else {
        if (this.table_data.length) {
          this.detailShow(this.table_data[0].id, true, true);
        } else {
          this.icdDefaultValues();
          this.doctorDefaultValues();
        }

        this.form_store = true;
      }

      this.data.rha_cpt_id = this.rha.cpt_id;
      this.form_dialog = true;
    },
    reviewAlert(review, id) {
      this.$swal
        .fire(msgConfirm(`¿Desea marcar la revisión de esta carta?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            storeUpdate(
              "rha/letters/review",
              this.login.token,
              0,
              {
                review: review,
                rha_letter_id: id,
                user_id: this.login.id,
              },
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.loading = false;
                this.tableData();
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    formDialogClose() {
      this.form_dialog = false;

      this.dataDefaultValues();
      this.$refs.form_submit.resetValidation();
      this.icdDefaultValues();
      this.$refs.icd_form.resetValidation();
      this.doctorDefaultValues();
      //this.$refs.doctor_form.resetValidation();
    },
    icdAdd() {
      this.icd_loading = true;

      if (this.$refs.icd_form.validate() && this.icd.id) {
        let icd_exists = false;

        for (let i = 0; i < this.data.icds.length; i++) {
          if (
            this.data.icds[i].icd_id == this.icd.id &&
            this.data.icds[i].active
          ) {
            icd_exists = true;
          }
        }

        if (!icd_exists) {
          this.data.icds.push({
            id: null,
            icd_id: this.icd.id,
            code: this.icd.code,
            icd: this.icd.icd,
            active: true,
          });

          if (this.data.icds.length == 1 && this.rha.insured_id) {
            this.icds_loading = true;

            Axios.get(
              URL_API +
                "/insureds/sinister/report?insured_id=" +
                this.rha.insured_id +
                "&icd_id=" +
                this.icd.id,
              headersToken(this.login.token)
            ).then((res) => {
              this.sinister_rep = res.data.data;
              this.icds_loading = false;
            });
          }
        } else {
          this.$swal.fire(
            msgAlert("warning", "El ICD seleccionado ya ha sido agregado")
          );
          this.icd_loading = false;
        }
      } else {
        this.$swal.fire(
          msgAlert("warning", "Selecciona una descripción de ICD")
        );
        this.icd_loading = false;
      }
    },
    icdDefaultValues() {
      this.icd = {};
    },
    icdRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar ICD (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.icds[i].id !== null) {
              this.data.icds[i].active = false;
            } else {
              this.data.icds.splice(i, 1);
            }

            if (this.data.icds.length == 0 && this.rha.insured_id) {
              this.sinister_rep = null;
            }
          }
        });
    },
    commentDialog(item) {
      this.comment = item;
      this.comment_dialog = true;
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    doctorDefaultValues() {
      this.doctor = {
        id: null,
        name: "",
        first_surname: "",
        second_surname: "",
        specialty_type: null,
        type: null,
        cpt: null,
        visits: "1",
        amount: "0",
        total_amount: 0,
      };
    },
    doctorSearch() {
      const search = {
        name: this.doctor.name,
        first_surname: this.doctor.first_surname,
        second_surname: this.doctor.second_surname,
      };

      Axios.post(
        URL_API + "/doctors/search",
        search,
        headersToken(this.login.token)
      ).then((response) => {
        this.doctors = response.data.data;

        if (this.doctors.length === 1) {
          this.doctor.id = this.doctors[0].id;
          this.doctor.name = this.doctors[0].name;
          this.doctor.first_surname = this.doctors[0].first_surname;
          this.doctor.second_surname = this.doctors[0].second_surname;
          if (this.doctors[0].specialty_type_id) {
            show(
              "specialty/types",
              this.login.token,
              this.doctors[0].specialty_type_id
            ).then((response) => {
              this.doctor.specialty_type = response;
            });
          } else {
            this.doctor.specialty_type = null;
          }
        } else {
          if (this.doctors.length != 0) this.doctors_dialog = true;
        }
      });
    },
    doctorSelected(i) {
      this.doctor.id = this.doctors[i].id;
      this.doctor.name = this.doctors[i].name;
      this.doctor.first_surname = this.doctors[i].first_surname;
      this.doctor.second_surname = this.doctors[i].second_surname;

      if (this.doctors[i].specialty_type_id) {
        show(
          "specialty/types",
          this.login.token,
          this.doctors[i].specialty_type_id
        ).then((response) => {
          this.doctor.specialty_type = response;
          this.doctors_dialog = false;
        });
      } else {
        this.doctor.specialty_type = null;
        this.doctors_dialog = false;
      }
    },
    doctorLetterDlg() {
      this.doctor_letter = {
        name: "",
        first_surname: "",
        second_surname: "",
        specialty_type_id: null,
        doctors: [],
        search: "",
        find: false,
      };
      this.doctor_letter_dlg_ldg = false;
      this.doctor_letter_dlg = true;
    },
    doctorLetterSearch() {
      this.doctor_letter.find = false;
      this.doctor_letter_dlg_ldg = true;

      Axios.post(
        `${URL_API}/doctors/search`,
        this.doctor_letter,
        headersToken(this.login.token)
      ).then((res) => {
        this.doctor_letter.doctors = res.data.data;
        this.doctor_letter_dlg_ldg = false;

        this.doctor_letter.find = true;

        if (this.doctor_letter.doctors.length == 0) {
          this.$swal.fire(msgAlert("warning", "No se econtraron resultados"));
        }
      });
    },
    doctorLetterVisitsDlg(item, index) {
      this.doctor_letter_visits_index = index;
      this.doctor_letter_visits = {
        id: item == null ? null : item.id,
        active: true,
        full_name: item == null ? "PROVISIONAL SM" : item.full_name,
        name: item == null ? "PROVISIONAL" : item.name,
        first_surname: item == null ? "SM" : item.first_surname,
        second_surname: item == null ? "" : item.second_surname,
        specialty_type: item == null ? null : item.specialty_type,
        doctor_id: item == null ? null : item.doctor_id,
        type: index == null ? null : item.type,
        visits: index == null ? "1" : item.visits,
        cpt: index == null ? null : item.cpt,
        amount: index == null ? "0" : item.amount,
        total_amount: index == null ? 0 : item.total_amount,
      };
      this.doctor_letter_visits_dlg_ldg = false;
      this.doctor_letter_visits_dlg = true;
    },
    doctorLetterVisits(calc) {
      this.doctor_types_ldg = true;

      Axios.get(
        URL_API +
          "/rha/" +
          this.rha_id +
          "/letters/doctors/" +
          this.doctor_letter_visits.type.id,
        headersToken(this.login.token)
      ).then((res) => {
        this.doctors_type = res.data.data;

        if (this.doctors_type.length > 0) {
          if (this.doctors_type.length == 1) {
            this.setDoctorVals(this.doctors_type[0]);
          } else {
            this.doctors_type_dlg = true;
          }
        }

        // if (!this.doctor_edit) {
        //   this.doctor_letter_visits.visits = 1;
        // }

        if (
          this.doctor_letter_visits.type == null ||
          this.doctor_letter_visits.type.id == 0 ||
          this.doctor_letter_visits.type.id > 3
        ) {
          this.doctor_visits_show = false;
        } else {
          this.doctor_visits_show = true;
        }

        if (calc) {
          this.doctorLetterAmountCalc();
        }

        this.doctor_types_ldg = false;
      });
    },
    doctorLetterAmountCalc() {
      if (
        this.doctor_letter_visits.cpt &&
        this.doctor_letter_visits.visits != "" &&
        this.doctor_letter_visits.type
      ) {
        let anes = 0;
        let assit = 0;
        if (this.doctor_letter_visits.type.id === 6) anes = 1;
        if (this.doctor_letter_visits.type.id === 5) assit = 1;
        show(
          "rha/letters/amount/cpt/" +
            this.doctor_letter_visits.cpt.id +
            "/anesthesiologist/" +
            anes +
            "/assistant/" +
            assit +
            "/rha",
          this.login.token,
          this.rha_id
        ).then((res) => {
          // console.log(res);
          this.doctor_letter_visits.amount = res;
          // this.doctor_letter_visits.total_amount =
          //   parseFloat(this.doctor_letter_visits.amount) *
          //   parseInt(this.doctor_letter_visits.visits);
          // this.$forceUpdate();
          this.doctorLetterAmountModify();
        });
      } else {
        this.doctor.total_amount = 0;
      }
    },
    doctorLetterAmountModify() {
      if (
        this.doctor_letter_visits.cpt != null &&
        this.doctor_letter_visits.visits != ""
      ) {
        this.doctor_letter_visits.total_amount =
          parseFloat(this.doctor_letter_visits.amount) *
          parseInt(this.doctor_letter_visits.visits);
        this.$forceUpdate();
      } else {
        this.doctor_letter_visits.total_amount = 0;
      }
    },
    cptsDlg() {
      this.cpts_dlg_ldg = false;
      this.tbl_cpts = [];
      this.cpt_search = {
        code: "",
        cpt: "",
        cpt_system_id: null,
        cpt_specialty_id: null,
      };
      this.cpts_dlg = true;
    },
    cptsSearch() {
      if (
        this.cpt_search.cpt_system_id ||
        this.cpt_search.cpt_specialty_id ||
        this.cpt_search.code != "" ||
        this.cpt_search.cpt != ""
      ) {
        this.cpts_dlg_ldg = true;

        Axios.get(
          URL_API +
            "/cpts?cpt_system_id=" +
            this.cpt_search.cpt_system_id +
            "&cpt_specialty_id=" +
            this.cpt_search.cpt_specialty_id +
            "&code=" +
            this.cpt_search.code +
            "&cpt=" +
            this.cpt_search.cpt,
          headersToken(this.login.token)
        ).then((res) => {
          this.tbl_cpts = res.data.data;
          this.cpts_dlg_ldg = false;

          if (this.tbl_cpts.length == 0) {
            this.$swal.fire(msgAlert("warning", "No se econtraron resultados"));
          }
        });
      }
    },
    cptsSelect(item) {
      this.doctor_letter_visits.cpt = item;
      this.doctorLetterAmountCalc();
      this.cpts_dlg = false;
    },
    doctorLetterHandle() {
      if (this.$refs.doctor_letter_visits_form.validate()) {
        this.doctor_letter_visits_dlg_ldg = true;

        if (parseInt(this.doctor_letter_visits.visits) != 0) {
          if (this.doctor_letter_visits_index == null) {
            this.data.doctors.push(this.doctor_letter_visits);
          } else {
            Object.assign(
              this.data.doctors[this.doctor_letter_visits_index],
              this.doctor_letter_visits
            );
          }

          this.doctor_letter_visits_dlg = false;
          this.doctor_letter_dlg = false;

          this.$refs.doctor_letter_visits_form.resetValidation();
        } else {
          this.$swal.fire(
            msgAlert("error", "El campo de 'Visitas' no puede ser 0")
          );
        }

        this.doctor_letter_visits_dlg_ldg = false;
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido para Honorarios Médicos, revisa los detalles señalados"
          )
        );
      }
    },
    doctorLetterRemove(index) {
      this.$swal
        .fire(msgConfirm("¿Confirma eliminar honorario (" + (index + 1) + ")?"))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.doctors[index].id == null) {
              this.data.doctors.splice(index, 1);
            } else {
              this.data.doctors[index].active = false;
            }
          }
        });
    },
    doctorNewDlg() {
      this.doctor_new = {
        name: this.doctor_letter.name,
        first_surname: this.doctor_letter.first_surname,
        second_surname: this.doctor_letter.second_surname,
        email: "",
        phone: "",
        code_tax: "",
        license: "",
        specialty_type_id: this.doctor_letter.specialty_type_id,
        provider_id: null,
      };
      this.doctor_new_dlg_ldg = false;
      this.doctor_new_dlg = true;
    },
    doctorNewHandle() {
      if (this.$refs.doctor_new_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma agregar al médico?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.doctor_new_dlg_ldg = true;

              Axios.post(
                `${URL_API}/doctors/rha_new_doctor`,
                this.doctor_new,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.doctor_letter = {
                    name: this.doctor_new.name,
                    first_surname: this.doctor_new.first_surname,
                    second_surname: this.doctor_new.second_surname,
                    specialty_type_id: this.doctor_new.specialty_type_id,
                    doctors: res.data.data,
                    search: "",
                    find: true,
                  };
                  this.doctor_new_dlg = false;
                  this.doctorLetterVisitsDlg(
                    this.doctor_letter.doctors[0],
                    null
                  );
                } else {
                  console.log(res.data.message);
                }

                this.doctor_new_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(msgAlert("error", "Revisa los detalles señalados"));
      }
    },
    setDoctorVals(item) {
      this.doctor.name = item.doctor_name;
      this.doctor.first_surname = item.doctor_first_surname;
      this.doctor.second_surname = item.doctor_second_surname;
      this.doctor.specialty_type = item.specialty_type;
      this.doctor.doctor_id = item.doctor_id;

      this.doctors_type_dlg = false;
    },
    // doctorRemove(i) {
    //   this.$swal
    //     .fire(msgConfirm(`¿Confirma eliminar médico (${i + 1})?`))
    //     .then((response) => {
    //       if (response.isConfirmed) {
    //         if (this.data.doctors[i].id !== null)
    //           this.data.doctors[i].active = false;
    //         else this.data.doctors.splice(i, 1);
    //       }
    //     });
    // },
    // doctorEditForm(i) {
    //   this.doctor = {
    //     letter_doctor_id: this.data.doctors[i].letter_doctor_id,
    //     name: this.data.doctors[i].name,
    //     first_surname: this.data.doctors[i].first_surname,
    //     second_surname: this.data.doctors[i].second_surname,
    //     specialty_type: this.data.doctors[i].specialty_type,
    //     type: this.data.doctors[i].type,
    //     cpt: this.data.doctors[i].cpt,
    //     visits: this.data.doctors[i].visits,
    //     amount: this.data.doctors[i].amount,
    //     total_amount: this.data.doctors[i].total_amount,
    //   };

    //   this.doctor_edit_row = i;
    //   this.doctor_edit = true;
    //   this.doctorLetterVisits(false);
    // },
    doctorEditCancel() {
      this.doctorDefaultValues();
      this.$refs.doctor_form.resetValidation();
      this.doctor_edit_row = 0;
      this.doctor_edit = false;
      this.doctorLetterVisits(false);
      this.doctor_loading = false;
    },
    doctorEdit() {
      this.doctor_loading = true;

      this.data.doctors[this.doctor_edit_row].name =
        this.doctor.name.toUpperCase();
      this.data.doctors[this.doctor_edit_row].first_surname =
        this.doctor.first_surname.toUpperCase();
      this.data.doctors[this.doctor_edit_row].second_surname =
        this.doctor.second_surname.toUpperCase();
      this.data.doctors[this.doctor_edit_row].specialty_type =
        this.doctor.specialty_type;
      this.data.doctors[this.doctor_edit_row].type = this.doctor.type;
      this.data.doctors[this.doctor_edit_row].cpt = this.doctor.cpt;
      this.data.doctors[this.doctor_edit_row].visits = this.doctor.visits;
      this.data.doctors[this.doctor_edit_row].amount = this.doctor.amount;
      this.data.doctors[this.doctor_edit_row].total_amount =
        this.doctor.total_amount;

      this.doctorEditCancel();
    },
    formSubmit() {
      let total_amount = parseFloat(this.data.authorized_amount);

      for (const doctor of this.data.doctors) {
        total_amount += parseFloat(doctor.amount);
      }

      let amount_exceeded = false;

      if (
        this.rha.insured_id &&
        total_amount > parseFloat(this.rha.contractor_detail.max_assured)
      ) {
        amount_exceeded = true;
      }

      if (this.data.icds.length > 0) {
        if (!amount_exceeded) {
          if (this.$refs.form_submit.validate()) {
            this.$swal
              .fire(
                msgConfirm(
                  `¿Confirma la ${
                    this.form_store ? "creación" : "edición"
                  } del registro?`
                )
              )
              .then((response) => {
                if (response.isConfirmed) {
                  this.loading = true;

                  storeUpdate(
                    "rha/letters",
                    this.login.token,
                    this.form_store ? 0 : this.data.id,
                    this.data,
                    this.login.id
                  ).then((response) => {
                    this.$swal.fire(
                      msgAlert(
                        response.success ? "success" : "error",
                        response.message
                      )
                    );

                    if (response.success) {
                      this.formDialogClose();
                      this.tableData();
                    } else {
                      console.log(response.message);
                    }

                    this.loading = false;
                  });
                }
              });
          } else {
            this.$swal.fire(
              msgAlert(
                "error",
                "Formulario invalido, revisa los detalles señalados"
              )
            );
          }
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Monto máximo de contratante excedido (" +
                this.numberFormat(total_amount) +
                " > " +
                this.numberFormat(this.rha.contractor_detail.max_assured) +
                "), no se puede crear carta"
            )
          );
        }
      } else {
        this.$swal.fire(msgAlert("error", "Se debe de agregar mínimo un ICD"));
      }
    },
    detailShow(id, edit = false, new_letter = false, last_letter = false) {
      this.loading = true;
      show("rha/letters", this.login.token, id).then((response) => {
        this.data = response;
        this.data.fee_coinsurance = this.data.fee_coinsurance + "";
        this.data.percentage_coinsurance =
          this.data.percentage_coinsurance + "";
        if (new_letter) this.data.id = null;

        this.last_letter_btn = last_letter ? true : false;

        this.doctors_total_amount = 0;
        this.last_letter_action = true;

        for (var i = this.data.doctors.length - 1; i >= 0; i--) {
          this.data.doctors[i].type =
            this.doctor_types[this.data.doctors[i].type_id - 1];
          if (new_letter) this.data.doctors[i].id = null;

          this.doctors_total_amount += parseFloat(
            this.data.doctors[i].total_amount
          );

          if (this.last_letter_action && !this.data.doctors[i].doctor_id) {
            this.last_letter_action = false;
          }
        }

        this.total_authorized =
          this.doctors_total_amount +
          (this.data.amount_response_id == 1
            ? parseFloat(this.data.authorized_amount)
            : 0);

        this.doctors_total_amount = Number(
          this.doctors_total_amount.toFixed(2)
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        this.total_authorized = Number(
          this.total_authorized.toFixed(2)
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        if (!edit) this.detail_dialog = true;
        this.loading = false;
      });
    },
    detailDesactivate(id) {
      this.$swal
        .fire(msgConfirm("Confirma eliminar el registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("rha/letters", this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.detail_dialog = false;
                  this.loading = false;
                  this.tableData();
                } else {
                  console.log(response.message);
                }
              }
            );
          }
        });
    },
    historyReviewsDialog(item) {
      this.history_reviews = null;
      this.history_reviews_dialog_loading = true;
      this.history_reviews_dialog = true;

      Axios.get(
        `${URL_API}/rha/letters/review/history/${item.id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.history_reviews = response.data.data;
        this.history_reviews_dialog_loading = false;
      });
    },
    lastLetter(data) {
      this.$swal
        .fire(msgConfirm("Confirma generar carta definitiva registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            Axios.post(
              `${URL_API}/rha/letters/last`,
              {
                id: data.id,
                rha_id: data.rha_id,
                doctors: data.doctors,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.detail_dialog = false;
                this.loading = false;
                this.rha.last_rha_letter_id = data.id;
                this.tableData();
              } else {
                console.log(resp.data.message);
              }
            });
          }
        });
    },
    doctorSearchDialog(rha_letter_doctor, index) {
      this.doctor_search_data = {
        doctor: {
          name: rha_letter_doctor.name,
          first_surname: rha_letter_doctor.first_surname,
          second_surname: rha_letter_doctor.second_surname,
          specialty_type: rha_letter_doctor.specialty_type,
        },
        doctors: [],
        find: null,
        index: index,
      };

      this.doctor_search_dialog = true;
      this.doctor_search_dialog_loading = false;
    },
    doctorSearchAction() {
      if (this.$refs.doctor_search_form.validate()) {
        this.doctor_search_dialog_loading = true;
        this.doctor_search_data.find = null;
        Axios.post(
          URL_API + "/doctors/search",
          {
            name: this.doctor_search_data.doctor.name,
            first_surname: this.doctor_search_data.doctor.first_surname,
            second_surname: this.doctor_search_data.doctor.second_surname,
          },
          headersToken(this.login.token)
        ).then((resp) => {
          this.doctor_search_data.doctors = resp.data.data;
          this.doctor_search_data.find =
            this.doctor_search_data.doctors.length > 0;
          this.doctor_search_dialog_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    doctorSearchSelected(doctor_selected) {
      this.doctor_search_dialog_loading = true;

      let doctor_repeat = false;
      for (let doctor of this.data.doctors) {
        if (doctor.doctor_id == doctor_selected.id) {
          doctor_repeat = true;
          break;
        }
      }

      if (!doctor_repeat) {
        this.data.doctors[this.doctor_search_data.index].name =
          doctor_selected.name;
        this.data.doctors[this.doctor_search_data.index].first_surname =
          doctor_selected.first_surname;
        this.data.doctors[this.doctor_search_data.index].second_surname =
          doctor_selected.second_surname;
        this.data.doctors[
          this.doctor_search_data.index
        ].specialty_type.specialty_type = doctor_selected.specialty_type_name;
        this.data.doctors[this.doctor_search_data.index].specialty_type.id =
          doctor_selected.specialty_type_id;
        this.data.doctors[this.doctor_search_data.index].specialty_type_id =
          doctor_selected.specialty_type_id;
        this.data.doctors[this.doctor_search_data.index].doctor_id =
          doctor_selected.id;

        this.last_letter_action = true;
        for (let doctor of this.data.doctors) {
          if (!doctor.doctor_id) {
            this.last_letter_action = false;
            break;
          }
        }

        this.doctor_search_dialog = false;
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "El médico seleccionado ya se encuentra en el listado de honorarios"
          )
        );
      }

      this.doctor_search_dialog_loading = false;
    },
    itemRowBackground: function (item) {
      return item.id == this.rha.last_rha_letter_id ? "orange" : "";
    },
    rhaLetterCommentDlg(item) {
      this.rha_letter_comment_dlg_ldg = false;
      this.rha_letter_comment = {
        rha_letter_id: item.id,
        comment: item.comment,
        icd_extended: item.icd_extended,
        studies: item.studies,
        observation: item.observation,
        reg: item.comment ? true : false,
      };
      this.rha_letter_comment_dlg = true;
    },
    rhaLetterComment() {
      if (this.$refs.rha_letter_comment_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma enviar información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.rha_letter_comment_dlg_ldg = true;

              Axios.post(
                URL_API + "/rha/letters/comment",
                this.rha_letter_comment,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.rha_letter_comment_dlg = false;
                  this.tableData();
                } else {
                  console.log(res.data.message);
                }

                this.rha_letter_comment_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    assesmentRepDlg(id) {
      this.assesment_rep_dlg_ldg = true;
      this.assesment_rep = null;
      this.assesment_rep_dlg = true;

      Axios.get(
        URL_API + "/rha/letters/" + id + "/assessment",
        headersToken(this.login.token)
      ).then((res) => {
        this.assesment_rep = res.data.data;
        this.assesment_rep_dlg_ldg = false;
      });
    },
    removeLastLetter(rha) {
      this.$swal
        .fire(msgConfirm(`¿Confirma ELIMINAR la carta definitiva?`))
        .then((res) => {
          if (res.isConfirmed) {
            this.table_loading = true;

            Axios.post(
              URL_API + "/rha/letters/last/remove",
              rha,
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.init();
              } else {
                console.log(res.data.message);
                this.table_loading = false;
              }
            });
          }
        });
    },
    cptIsBlocked() {
      this.cpt_blocked == null;

      if (this.rha.insured_id) {
        this.loading = true;

        Axios.get(
          URL_API +
            "/contractor/" +
            this.rha.contractor_detail.id +
            "/cpt_blocked/" +
            this.data.rha_cpt_id,
          headersToken(this.login.token)
        ).then((res) => {
          this.cpt_blocked = res.data.data;
          this.loading = false;
        });
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    if (this.login.role_id == 1) {
      Axios.get(
        `${URL_API}/providers/type/4`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.providers = resp.data.data;
        this.providers_loading = false;
      });
    } else {
      Axios.get(
        URL_API + "/user/" + this.login.id + "/providers",
        headersToken(this.login.token)
      ).then((resp) => {
        this.providers = resp.data.data;
        this.providers_loading = false;
      });
    }

    catalog("doctor_types", this.login.token).then((response) => {
      this.doctor_types = response;
      this.doctor_types_ldg = false;
    });
    catalog("rha_amount_responses", this.login.token).then((response) => {
      this.amount_responses = response;
      this.amount_responses_loading = false;
    });
    catalog("icds", this.login.token).then((response) => {
      this.icds = response;
      this.icds_loading = false;
    });
    catalog("specialty_types", this.login.token).then((response) => {
      this.specialty_types = response;
      this.specialty_types_loading = false;
    });
    catalog("cpts", this.login.token).then((response) => {
      this.cpts_slct = response;
      this.cpts_slct_ldg = false;
    });
    index("rhas/doctors/" + this.rha_id, this.login.token, []).then(
      (response) => {
        this.doctors_resume = response.data;
      }
    );

    Axios.get(URL_API + "/cpt/systems", headersToken(this.login.token)).then(
      (res) => {
        this.cpt_systems = res.data.data;
        this.cpt_systems_ldg = false;
      }
    );
    Axios.get(
      URL_API + "/cpt/specialties",
      headersToken(this.login.token)
    ).then((res) => {
      this.cpt_specialties = res.data.data;
      this.cpt_specialties_ldg = false;
    });

    this.init();
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
.border {
  border: 1px solid rgb(96, 96, 96);
}
</style>